import React from "react"
import { Container, Table, Header, Icon, Breadcrumb } from "semantic-ui-react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TextbooksPage = ({ data }) => (
  <Layout>
    <SEO title="Textbooks" />
    <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          Home
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Textbooks</Breadcrumb.Section>
      </Breadcrumb>

      <Header as="h1" dividing>
        <Icon name="book" />
        <Header.Content>
          Textbooks
          <Header.Subheader>
            Find Misconceptions by Textbook Section
          </Header.Subheader>
        </Header.Content>
      </Header>
      <p>
        If you teach using one of the textbooks below, you can look up which
        misconceptions might appear when your students work through each chapter
        of that book.
      </p>
      <Table celled compact collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Language</Table.HeaderCell>
            <Table.HeaderCell>Textbook</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.allMdx.nodes
              .filter(node => node.frontmatter.status !== "draft")
              .map((node, index) => (
            <Table.Row key={index}>
              <Table.Cell>{node.frontmatter.pl}</Table.Cell>
              <Table.Cell>
                {node.frontmatter.author}
                <br/>
                <Link to={"/textbooks/" + node.parent.name}>
                  <strong>{node.frontmatter.title}</strong>
                  <br />
                  {node.frontmatter.subtitle
                    ? node.frontmatter.subtitle + ", "
                    : null}
                  {node.frontmatter.edition}
                  {node.frontmatter.language ? " ("+node.frontmatter.language+")" : null}
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Container>
  </Layout>
)

export default TextbooksPage

export const query = graphql`
  {
    allMdx(
      filter: { fileAbsolutePath: { glob: "**/src/data/textbooks/*.md" } }
    ) {
      nodes {
        frontmatter {
          author
          title
          subtitle
          edition
          pl
          language
          status
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  }
`
